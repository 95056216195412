<template>
  <div class="uk-container">
    <v-row class="uk-container uk-margin-large-top">
      <v-col cols="12">
        <h1 class="font-weight-bold" :style="{ color: this.fontWeightBold }">
          <base-arrow-back-icon @click="goTwoSteps ? $router.go(-3) : $router.go(-1)">{{
            rightArrowIcon
          }}</base-arrow-back-icon>

          الوحدات
        </h1>
      </v-col>
      <v-col cols="12">
        <table-actions-nav
          type="module"
          v-model="search"
          :data="export_things"
          :options="{ withAddNew: true, withSearch: true, withExport: true }"
        ></table-actions-nav>
      </v-col>
    </v-row>
    <v-row class="uk-margin-small-top uk-margin-large-bottom">
      <template>
        <v-data-table
          :headers="modulesHeaders"
          :items="modules"
          :items-per-page="5"
          :search="search"
          class="elevation-1 w-100"
          :footer-props="{
            itemsPerPageText: 'عدد الأسطر في الجدول:',
          }"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <table-actions
              :id="item.id"
              @edit="edit(item.id)"
              @remove="remove(item.id)"
              @show="show(item.id)"
              @modules="goTo(item.id)"
              module
            ></table-actions>
          </template>
        </v-data-table>
      </template>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TableActionsNav from "../../Components/TableActionsNav";
import TableActions from "../../Components/TableActions";
import BaseArrowBackIcon from "@/core/Base/Arrows/BaseArrowBackIcon";
export default {
  components: {
    TableActions,
    TableActionsNav,
    BaseArrowBackIcon,
  },
  data() {
    return {
      search: "",
      modulesHeaders: [
        {
          text: "اسم الوحدة",
          value: "title",
          align: "center",
        },
        {
          text: "الشرح",
          value: "description",
          align: "center",
        },
        { text: "", value: "actions", align: "center" },
      ],
    };
  },
  computed: {
    ...mapState(["rtl"]), // to decide the direction of the arrow
    ...mapGetters({
      modules: "getModules",
    }),
    goTwoSteps () {
      if (this.$route.query.fromWhere === 'addEdit') {
        return true;
      } else {
        return false;
      }
    },
    export_things() {
      return {
        name: "Modules",
        fields: {
          " العنوان": "title",
          "لشرح ": "description",
        },
        data: this.modules,
      };
    },
  },
  methods: {
    ...mapActions(["fetchModules", "deleteModuleInstructor"]),
    goTo(id) {
      console.log(id);
      this.$router.push({ name: "lecturesIndex", params: { id: id } });
    },
    remove(id) {
      this.deleteModuleInstructor(id).then(() => {
        this.fetchModules(this.$route.params.id);
      });
    },
    edit(id) {
      this.$router.push({
        name: "editModuleInstructor",
        params: { id: id },
      });
    },
    show(id) {
      this.$router.push({
        name: "showModule",
        params: { id: id },
      });
    },
  },
  mounted() {
    this.fetchModules(this.$route.params.id);
  },
};
</script>